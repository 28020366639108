/* Styles for the Projects Grid */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    padding: 20px;
}

.projectsTitle {
    margin-top: 10%;
}

.project-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    background: rgba(133, 88, 252, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid #8558fc3d;
    color: blanchedalmond;
    padding: 0.5rem 1rem;
    align-items: center;
}

.project-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}
